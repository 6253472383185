<template>
    <v-dialog v-model="visible" max-width="1000">
        <div class="docWrap" v-if="visible">
            <div ref="docfile"></div>
        </div>
        <!-- <pdf  v-if="visible && pdfFileUrl" :src="pdfFileUrl"></pdf> -->
        <div class="pdfWrap" v-if="visible && pdfFileUrl">
            <!-- <div id="pdfViewer" ref="pdfViewer"></div> -->
            <iframe :src="pdfFileUrl" width="100%" height="650px"></iframe>
        </div>
        <v-snackbar v-model="snackbar" centered>
            {{message}}
        </v-snackbar>
    </v-dialog>
</template>

<script>
    // import pdf from 'vue-pdf'
    export default {
        name: "jobModal",
        // components: {pdf},
        data(){
            return{
                visible: false,
                snackbar: false,
                message: null,
                fileUrl:'',
                pdfFileUrl:'',
                docxOptions:{},
            }
        },
        mounted(){
            
        },
        methods: {
            openDialog(fileUrl){
                this.fileUrl = ''
                this.pdfFileUrl = ''
                this.visible = true;
                if(window.location.protocol === 'https:'){
                    fileUrl = fileUrl.replace('http://', 'https://')
                }
                let suffix = this.get_suffix(fileUrl)
                if(suffix.indexOf('doc') > -1){
                    // fileUrl = fileUrl.replace('http://dfxy.yunyuzh.com/college', '')
                    setTimeout(() => {
                        this.showWordFile(fileUrl)
                    }, 500);
                    
                } else if(suffix.indexOf('pdf') > -1){
                    console.log('pdf')
                    
                    // fileUrl = fileUrl.replace('http://', 'https://')
                    // this.pdfFileUrl = '/pdfweb/generic/web/viewer.html?file=' + encodeURIComponent(fileUrl)
                    this.pdfFileUrl = '/pdfweb/generic/web/viewer.html?file=' + encodeURIComponent(fileUrl)
                    setTimeout(() => {
                        // this.showPDFFile(fileUrl)
                        // window.PDFObject.embed(fileUrl, '#pdfViewer');
                    }, 500);
                }else {
                    this.snackbar = true;
                    this.message = '该文件格式暂不支持预览！';
                }
                
                
            },
            closeDialog(){
                this.visible = false;
            },
            get_suffix(filename) {
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                if (pos != -1) {
                    suffix = filename.substring(pos)
                }
                return suffix
            },
            showWordFile(fileUrl){
                // const jszip = new window.JSZip()
                // jszip.loadAsync(fileUrl).then((zip) => {
                //     console.log(zip)
                // })
                let config = {
                    responseType: 'arraybuffer',
                };
                this.$http.get(fileUrl, config).then((res) => {
                    console.log(res)
                    // const blob = new Blob([res.data], {type: 'application/octet-stream'});
                    let bodyContainer = this.$refs.docfile
                    window.docx.renderAsync(
                        // 预览的数据，拿到接口返回值，（具体看文件流在那个值下边，看数据层级）
                        res.data, // Blob | ArrayBuffer | Uint8Array, 可以是 JSZip.loadAsync 支持的任何类型
                        bodyContainer, // HTMLElement 渲染文档内容的元素,
                        null, // HTMLElement, 用于呈现文档样式、数字、字体的元素。如果为 null，则将使用 bodyContainer。
                        this.docxOptions // 配置  暂时没配置  看自己需求配置
                    )
                })
                
            },
            showPDFFile(fileUrl){
                // const jszip = new window.JSZip()
                // jszip.loadAsync(fileUrl).then((zip) => {
                //     console.log(zip)
                // })
                let config = {
                    responseType: 'arraybuffer',
                };
                this.$http.get(fileUrl, config).then((res) => {
                    console.log(res)
                    // const blob = new Blob([res.data], {type: 'application/octet-stream'});
                    window.PDFObject.embed(res.data, '#pdfViewer');
                })
                
            }
            
        }
    }
</script>

<style scoped>

</style>
