<template>
    <v-card>
        <v-toolbar color="cyan" dark flat>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>招聘</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showLogoutDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title @change="switchTab">
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab v-for="item in tabs" :key="item.key">
                        {{ item.text }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
            <v-tab-item key="0">
                <v-img max-height="600"  aspect-ratio="1.778" :src="require('../assets/stip_background.png')"></v-img>
            </v-tab-item>
            <v-tab-item key="1">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field
                                        v-model="jobCondition.name"
                                        label="岗位"
                                        hint="请输入岗位名称"
                                        clearable
                                        clear-icon="mdi-close-circle"
                                        prepend-icon="mdi-account"
                                ></v-text-field>
                            </v-col>

                        </v-row>
                        <v-spacer></v-spacer>
                        <v-data-table
                                :headers="jobHeaders"
                                :items="jobList"
                                :loading="jobLoading"
                                :sortBy="['sortNo']"
                                loading-text="数据加载中......"
                                no-data-text="当前无数据......"
                                :items-per-page="15"
                                class="elevation-1"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title>人员清单</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="getJobList">
                                        搜索
                                        <v-icon>mdi-text-search</v-icon>
                                    </v-btn>
                                    <v-btn class="ml-2 white--text" color="success" @click="addJob">
                                        新增
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.statusText="{item}">
                                <v-chip v-if="item.status == 1" color="success">使用</v-chip>
                                <v-chip v-if="item.status == 0" color="red" class="white--text">失效</v-chip>
                            </template>
                            <template v-slot:item.actions="{item}">
                                <v-btn class="white--text" @click="editJob(item)" color="cyan darken-1" text><v-icon>mdi-magnify-plus</v-icon>编辑</v-btn>
                                <v-btn class="white--text" @click="confirmDeleteJob(item)" color="pink lighten-11" text><v-icon>mdi-close-circle-outline</v-icon>删除</v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="2">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-select
                                        v-model="applyCondition.jobId"
                                        :items="dict.job"
                                        label="岗位"
                                        hint="请选择岗位"
                                        clearable
                                        clear-icon="mdi-close-circle"
                                        prepend-icon="mdi-gender-tie"
                                        item-text="label"
                                        item-value="value"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-select
                                        v-model="applyCondition.educationList"
                                        :items="dict.education"
                                        label="学历"
                                        hint="请选择学历"
                                        multiple
                                        clearable
                                        clear-icon="mdi-close-circle"
                                        prepend-icon="mdi-gender-tie"
                                        item-text="label"
                                        item-value="value"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field
                                        v-model="applyCondition.name"
                                        label="姓名"
                                        hint="请输入姓名"
                                        clearable
                                        clear-icon="mdi-close-circle"
                                        prepend-icon="mdi-account"
                                ></v-text-field>
                            </v-col>

                        </v-row>
                        <v-spacer></v-spacer>
                        <v-data-table
                                :headers="applyHeaders"
                                :items="applyList"
                                :loading="applyLoading"
                                :sortBy="['createTime']"
                                :sortDesc="[true]"
                                :show-select="true"
                                v-model="selectedApplys"
                                loading-text="数据加载中......"
                                no-data-text="当前无数据......"
                                :items-per-page="15"
                                class="elevation-1"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title>申请清单</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="getApplyList">
                                        搜索
                                        <v-icon>mdi-text-search</v-icon>
                                    </v-btn>
                                    <v-btn class="ml-2 white--text" color="success" @click="exportExcel">
                                        下载清单
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                    <v-btn class="ml-2 white--text" color="success" @click="exportExcelApplys">
                                        下载简历
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <!-- <template v-slot:header="{ props }">
                                <th v-for="header in props.headers" :key="header.text">
                                    <v-select
                                    v-if="header.value === 'education'"
                                    v-model="categoryFilterEducation"
                                    :items="dict.education"
                                    item-text="label"
                                    item-value="value"
                                    ></v-select>
                                </th>
                            </template> -->
                            <template v-slot:item.actions="{item}">
                                <v-btn class="white--text" @click="viewApplayWeb(item)" color="cyan darken-1" text>简历预览</v-btn>
                                <v-btn class="white--text" @click="downloadAttach(item)" color="cyan darken-1" text><v-icon>mdi-download</v-icon>下载</v-btn>
                                
                                <!--<v-btn class="white&#45;&#45;text" @click="editApply(item)" color="cyan darken-1" text><v-icon>mdi-magnify-plus</v-icon>编辑</v-btn>-->
                                <v-btn class="white--text" @click="confirmDeleteApply(item)" color="pink lighten-11" text><v-icon>mdi-close-circle-outline</v-icon>删除</v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <job-modal ref="jobModal" @close="getJobList"/>

        <v-dialog v-model="loginOutDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    确认框
                </v-card-title>
                <v-card-text>是否退出当前系统！</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="loginOutDialog = false">
                        取消
                    </v-btn>
                    <v-btn color="green darken-1" text @click="loginOut">
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteJobConfirm" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    确认框
                </v-card-title>
                <v-card-text>是否要删除当前岗位！</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteJobConfirm = false">
                        取消
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteJob">
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteApplyConfirm" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    确认框
                </v-card-title>
                <v-card-text>是否要删除当前申请！</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteApplyConfirm = false">
                        取消
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteApply">
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" centered>
            {{message}}
        </v-snackbar>

        <FileViewDialog ref="fileViewDialog"></FileViewDialog>
    </v-card>
</template>

<script>
    import JobModal from "./jobModal";
    import FileViewDialog from "../components/FileViewDialog";
    export default {
        name: "main",
        components: {JobModal,FileViewDialog},
        data(){
            return{
                selectedApplys:[],
                categoryFilterEducation: [],
                loginOutDialog: false,
                tab: null,
                tabs: [
                    {key: 'dashboard', text: '首页'},
                    {key: 'job', text: '岗位'},
                    {key: 'apply', text: '招聘'},
                ],
                snackbar: false,
                message: null,
                jobHeaders: [
                    {
                        text: '岗位',
                        value: 'name'
                    },
                    {
                        text: '发展方向',
                        value: 'develop'
                    },
                    {
                        text: '要求',
                        value: 'requirement'
                    },
                    {
                        text: '城市',
                        value: 'region'
                    },
                    {
                        text: '排序',
                        value: 'sortNo'
                    },
                    {
                        text: '状态',
                        value: 'statusText'
                    },
                    {
                        text: '操作',
                        value: 'actions'
                    },
                ],
                jobList: [],
                jobLoading: false,
                jobCondition: {
                    name: null,
                },
                applyHeaders: [
                    {
                        text: '序号',
                        value: 'id'
                    },
                    {
                        text: '岗位',
                        value: 'job'
                    },
                    {
                        text: '姓名',
                        value: 'name'
                    },
                    {
                        text: '性别',
                        value: 'gender'
                    },
                    {
                        text: '手机',
                        value: 'mobile'
                    },
                    {
                        text: '籍贯',
                        value: 'region'
                    },
                    {
                        text: '出生日期',
                        value: 'birthDate'
                    },
                    {
                        text: '学历',
                        value: 'education'
                    },
                    {
                        text: '毕业学校',
                        value: 'college'
                    },
                    {
                        text: '专业',
                        value: 'major'
                    },
                    {
                        text: '渠道',
                        value: 'jobSource'
                    },
                    {
                        text: '提交时间',
                        value: 'createTime'
                    },
                    {
                        text: '操作',
                        value: 'actions'
                    },
                ],
                applyList: [],
                applyLoading: false,
                applyCondition: {
                    jobId: null,
                    name: null,
                    educationList:[],
                },
                dict: {
                    job: [],
                    education: [
                        {value: '大专', label: '大专'},
                        {value: '本科', label: '本科'},
                        {value: '硕士', label: '硕士'},
                        {value: '博士', label: '博士'},
                    ],
                },
                deleteJobConfirm: false,
                deleteJobId: null,
                deleteApplyConfirm: false,
                deleteApplyId: null,
            }
        },
        watch:{
            // selectedApplys:function(v){
            //     console.log('1111111111111111111',v)
            // }
        },
        computed: {
            // customFilter(item) {
            //     if (this.categoryFilterEducation.length === 0) {
            //         return true;
            //     }
            //     return this.categoryFilterEducation.includes(item.education);
            // },
        },
        methods:{
            filterList(e){
                console.log('222222222222222222222',e)
            },
            switchTab(tab){
                if (tab == 1){
                    this.getJobList();
                }
                if (tab == 2){
                    this.getApplyList();
                    this.getApplyDict();
                }
            },
            showLogoutDialog(){
                this.loginOutDialog = true;
            },
            loginOut(){
                this.$ls.remove('USER');
                this.$router.push({path: '/'});
                this.loginOutDialog = false;
            },
            getJobList(){
                this.jobLoading = true;
                this.$http.post('job/list', this.jobCondition).then(({data}) => {
                    if (data.code == 0){
                        this.jobLoading = false;
                        this.jobList = data.jobList;
                    }else {
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网路请求失败！';
                })
            },
            addJob(){
                this.$refs.jobModal.add();
            },
            editJob(item){
                this.$refs.jobModal.edit(item);
            },
            confirmDeleteJob(item){
                this.deleteJobId = item.id;
                this.deleteJobConfirm = true;
            },
            deleteJob(){
                this.$http.delete('job/delete/' + this.deleteJobId).then(({data}) => {
                    if (data.code == 0){
                        this.deleteJobConfirm = false;
                        this.deleteJobId = null;
                        this.getJobList();
                    }else {
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
            getApplyList(){
                this.applyLoading = true;
                this.$http.post('apply/list', this.applyCondition).then(({data}) => {
                    if (data.code == 0){
                        this.applyLoading = false;
                        this.applyList = data.applyList;
                    } else{
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网路请求失败！';
                })
            },
            exportExcel(){
                const config = {
                    responseType: 'blob',
                };
                this.$http.post('apply/exportExcel', {},config).then((res) => {
                    const link = document.createElement('a');
                    const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', '申请记录.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            },
            exportExcelApplys(){
                let that = this
                const config = {
                    responseType: 'blob',
                };
                let params = {
                    applyIds: []
                }
                this.selectedApplys.forEach((item) => {
                    params.applyIds.push(item.applyId);
                })
                if(params.applyIds.length === 0){
                    this.snackbar = true;
                    this.message = '请选择要下载的简历！';
                    return false
                } 
                that.snackbar = true;
                that.message = '暂不支持批量下载的简历！';
                console.log(params,config);
                // if(this.isloading) return false
                // this.isloading = true
                // // http://127.0.0.1:9074/college/file/getFileByApplyList
                // this.$http.post('file/getFileByApplyList', params,config).then((res) => {
                //     that.isloading = false
                //     const link = document.createElement('a');
                //     const blob = new Blob([res.data], {type: 'application/zip'});
                //     link.style.display = 'none';
                //     link.href = URL.createObjectURL(blob);
                //     link.setAttribute('download', '批量简历.zip');
                //     document.body.appendChild(link);
                //     link.click();
                //     document.body.removeChild(link);
                // }).catch(() => {
                //     that.isloading = false
                //     this.snackbar = true;
                //     this.message = '批量下载的简历错误！';
                // })
            },
            editApply(item){
                console.log(item);
            },
            confirmDeleteApply(item){
                this.deleteApplyId = item.id;
                this.deleteApplyConfirm = true;
            },
            deleteApply(){
                this.$http.delete('apply/delete/' + this.deleteApplyId).then(({data}) => {
                    if (data.code == 0){
                        this.deleteApplyId = null;
                        this.deleteApplyConfirm = false;
                        this.getApplyList();
                        this.getApplyDict();
                    } else {
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
            viewApplayWeb(item){
                this.$http.post('file/getFileByApply', {applyId:item.applyId}).then(({data}) => {
                    const files = data.files;
                    if (files.length == 0){
                        this.snackbar = true;
                        this.message = '无简历可下载！';
                        return;
                    }
                    for (let i = 0; i < files.length; i++){
                        let config = {
                            responseType: 'arraybuffer',
                        };
                        let url = 'file/view/' + item.id + '?fileName=' + files[i].fileName;
                        console.log(url);
                        console.log("********111********");

                        if (files[i].category === 'file'){
                            url = 'file/download/' + item.id + '?fileName=' + files[i].fileName;
                            console.log(url);
                            console.log("********222********");
                            this.$refs.fileViewDialog.openDialog(files[i].fileUrl)
                            // window.open(files[i].fileUrl, "_blank")
                            // window.location.href = files[i].fileUrl;
                        }else{
                            console.log("********333********",config);

                        }
                    }

                })
            },
            downloadAttach(item){
                this.$http.post('file/getFileByApply', {applyId:item.applyId}).then(({data}) => {
                    const files = data.files;
                    if (files.length == 0){
                        this.snackbar = true;
                        this.message = '无简历可下载！';
                        return;
                    }
                    for (let i = 0; i < files.length; i++){
                        let config = {
                            responseType: 'arraybuffer',
                        };
                        let url = 'file/view/' + item.id + '?fileName=' + files[i].fileName;
                        console.log(url);
                        console.log("********111********");

                        if (files[i].category === 'file'){
                            url = 'file/download/' + item.id + '?fileName=' + files[i].fileName;
                            console.log(url);
                            console.log("********222********");
                            let fileUrl = files[i].fileUrl;
                            if(window.location.protocol === 'https:'){
                                fileUrl = fileUrl.replace('http://', 'https://')
                            }
                            window.location.href = fileUrl;
                        }else{

                            console.log("********333********");
                            this.$http.get(url, config).then((res) => {
                                const link = document.createElement('a');
                                const blob = new Blob([res.data], {type: 'application/octet-stream'});
                                link.style.display = 'none';
                                link.href = URL.createObjectURL(blob);
                                link.setAttribute('download', files[i].fileName);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            })
                        }
                    }

                })
            },
            getApplyDict(){
                this.$http.get('apply/dict').then(({data}) => {
                    this.dict.job = data.job;
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            }
        },
/*        watch:{
            tab(val){
                console.log(val);
            }
        }*/
    }
</script>

<style scoped>

</style>
