<template>
    <v-dialog v-model="visible" mmax-width="400">
        <v-card>
            <v-card-title>岗位信息</v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                                v-model="record.name"
                                :rules="rules.name"
                                label="岗位"
                                hint="请输入岗位"
                                clearable
                                clear-icon="mdi-close-circle"
                                prepend-icon="mdi-tie"
                        ></v-text-field>
                        <v-textarea
                                v-model="record.develop"
                                :rules="rules.develop"
                                label="发展方向"
                                clearable
                                clear-icon="mdi-close-circle"
                                prepend-icon="mdi-account-edit"
                                required
                        >
                        </v-textarea>
                        <v-textarea
                                v-model="record.requirement"
                                :rules="rules.requirement"
                                label="要求"
                                clearable
                                clear-icon="mdi-close-circle"
                                prepend-icon="mdi-account-edit"
                                required
                        >
                        </v-textarea>
                        <v-text-field
                                v-model="record.region"
                                :rules="rules.region"
                                label="城市"
                                hint="请选择城市"
                                clearable
                                clear-icon="mdi-close-circle"
                                prepend-icon="mdi-map-marker"
                                readonly
                                @click="showRegionDialog()"
                        ></v-text-field>
                        <v-select
                                v-if="record.id"
                                v-model="record.status"
                                :rules="rules.status"
                                :items="dict.status"
                                label="状态"
                                hint="请选择岗位状态"
                                clearable
                                clear-icon="mdi-close-circle"
                                prepend-icon="mdi-gender-male"
                                item-text="label"
                                item-value="value"
                        >
                        </v-select>
                        <v-text-field
                                v-model="record.sortNo"
                                label="排序"
                                hint="请输入排序序号"
                                clearable
                                clear-icon="mdi-close-circle"
                                prepend-icon="mdi-counter"
                        ></v-text-field>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" :disabled="!valid" color="success"  text large @click="addOrUpdate">确认</v-btn>
                <v-btn color="blue darken-1"  text large @click="closeDialog">返回</v-btn>
            </v-card-actions>
        </v-card>
        <region-modal ref="regionModal" @close="getRegionArea"/>
        <v-snackbar v-model="snackbar" centered>
            {{message}}
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import RegionModal from "./RegionModal";
    export default {
        name: "jobModal",
        components: {RegionModal},
        data(){
            return{
                visible: false,
                snackbar: false,
                message: null,
                valid: true,
                loading: false,
                record: {
                    name: null,
                    develop: null,
                    requirement: null,
                    region: null,
                    sortNo: null,
                },
                rules: {
                    name: [
                        v => !!v || '岗位是必选项',
                    ],
                    develop: [
                        v => !!v || '发展方向是必选项',
                    ],
                    requirement: [
                        v => !!v || '要求是必选项',
                    ],
                },
                dict: {
                    status: [
                        {value: 0, label: '失效'},
                        {value: 1, label: '使用'},
                    ]
                },
                provinceList: [],
            }
        },
        mounted(){
            this.getProvinceList();
        },
        methods: {
            addOrUpdate(){
                if (!this.$refs.form.validate()){
                    return;
                }
                this.loading = true;
                let url = 'job/add';
                if (this.record.id){
                    url = 'job/edit';
                }
                this.$http.post(url, this.record).then(({data}) => {
                    if (data.code == 0){
                        this.$emit('close');
                        this.visible = false;
                        this.loading = false;
                    } else {
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
            add(){
                this.visible = true;
                this.record = {
                    name: null,
                    develop: null,
                    requirement: null,
                    region: null,
                }
            },
            edit(item){
                this.visible = true;
                this.record = item;
            },
            closeDialog(){
                this.visible = false;
            },
            getRegionArea(region){
                this.record.region = region.province + '-' + region.city;
            },
            showRegionDialog(){
                this.$refs.regionModal.provinceList = this.provinceList;
                this.$refs.regionModal.show();
            },
            getProvinceList(){
                this.$http.post('apply/getProvince').then(({data}) => {
                    this.provinceList = data.provinceList;
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
        }
    }
</script>

<style scoped>

</style>
